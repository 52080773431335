import {
  Box,
  Heading,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Center,
  useToast
} from '@chakra-ui/react';
import React from 'react';
import { Form, Formik, Field, ErrorMessage } from "formik"
import validationSchema from './validationSchema';
import emailjs from 'emailjs-com';

const Banner = () => {
return (
  <Heading
    as='h1'
    fontSize='5xl'
    fontFamily={'Montserrat'}
    fontWeight={600}
    align='center'
    pt={10}
    pb={20}
  >
    CONTACT US
  </Heading>
)
}

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  occupation: "",
  message: "",
}

export default function Contact() {
  const toast = useToast()

  return (
    <Box
      bgGradient="linear(180deg, #181919 18.75%, #7D443C 100%)"
      color={'white'}
      fontFamily={'Montserrat'}
      pt={5}
    >
      <Banner />
      <Center pb={10}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            const from_name = values.firstName + " " + values.lastName
            const from_email = values.email
            const from_phone = values.phone
            const from_occupation = values.occupation
            const message = values.message
            try {
              const templateParams = {
                from_name,
                from_email,
                from_phone,
                from_occupation,
                message
              };
              await emailjs.send(
                'service_4ckxdyl', // process.env.REACT_APP_SERVICE_ID,
                'template_xt76c2x', // process.env.REACT_APP_TEMPLATE_ID,
                templateParams,
                'nfQp3m1BBqi3GYxga', // process.env.REACT_APP_USER_ID
              );
              toast({
                title: "Message sent.",
                description: "Thank you! We'll get back to you soon.",
                status: "success",
                duration: 9000,
                isClosable: true,
              })
              setSubmitting(false)
              // reset();
            } catch (e) {
              toast({
                title: "An error occurred.",
                description: "Please try again.",
                status: "error",
                duration: 9000,
                isClosable: true,
              })
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting, values, handleChange }) => (
            <Form>
              <Stack spacing={4}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  >
                  <Field name='firstName'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.firstName && form.touched.firstName} isRequired>
                        <FormLabel>First name</FormLabel>
                        <Input {...field} placeholder='Daniel' focusBorderColor='#3A355A' />
                        <ErrorMessage name='firstName' />
                      </FormControl>
                    )}
                  </Field>
                  <Field name='lastName'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.lastName && form.touched.lastName} isRequired>
                        <FormLabel>Last name</FormLabel>
                        <Input {...field} placeholder='Plainview' focusBorderColor='#3A355A' />
                        <ErrorMessage name='lastName' />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Field name='phone'>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                      <FormLabel>Phone</FormLabel>
                      <Input {...field} placeholder='(384) 756-4536' focusBorderColor='#3A355A' />
                      <ErrorMessage name='phone' />
                    </FormControl>
                  )}
                </Field>
                <Field name='email'>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input {...field} placeholder='daniel@pap.com' focusBorderColor='#3A355A' />
                      <ErrorMessage name='email' />
                    </FormControl>
                  )}
                </Field>
                <Field name='occupation'>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.occupation && form.touched.occupation} isRequired>
                      <FormLabel>Occupation</FormLabel>
                      <Input {...field} placeholder='CEO at Pan American Petroleum' focusBorderColor='#3A355A' />
                      <ErrorMessage name='occupation' />
                    </FormControl>
                  )}
                </Field>
                <Field name='message'>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.message && form.touched.message} isRequired>
                      <FormLabel>Message</FormLabel>
                      <Textarea {...field} placeholder="I'm an oil man …" focusBorderColor='#3A355A' />
                      <ErrorMessage name='message' />
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  bgColor={'gray.200'}
                  color={'black'}
                  fontWeight={400}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Submit
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Center>
    </ Box>
  );
}
