import { Box, Icon, VStack } from "@chakra-ui/react";
import { MdMailOutline, MdOutlinePodcasts } from 'react-icons/md';
import { FaTwitter, FaGithub, FaLinkedin, FaMeetup } from 'react-icons/fa';
import { twitterUrl, podcastUrl, calculatorGithubUrl, emailAddress, linkedinUrl, meetupUrl } from "../helpers/routes";

export default function FloatingSocialNavbar() {
  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      position="fixed"
      top={28}
      right="0"
      width={'32px'}
      height={'208px'}
      backgroundColor="#181919"
      border="1px solid white"
      p={2}
      zIndex={1}
    >
      <VStack>
        <a href={twitterUrl} target='_blank' rel='noopener noreferrer'>
          <Icon as={FaTwitter} color="white" boxSize={5} />
        </a>
        <a href={calculatorGithubUrl} target='_blank' rel='noopener noreferrer'>
          <Icon as={FaGithub} color="white" boxSize={5} />
        </a>
        <a href={linkedinUrl} target='_blank' rel='noopener noreferrer'>
          <Icon as={FaLinkedin} color="white" boxSize={5} />
        </a>
        <a href={emailAddress}>
          <Icon as={MdMailOutline} color="white" boxSize={5} />
        </a>
        <a href={meetupUrl} target='_blank' rel='noopener noreferrer'>
          <Icon as={FaMeetup} color="white" boxSize={5} />
        </a>
        <a href={podcastUrl} target='_blank' rel='noopener noreferrer'>
          <Icon as={MdOutlinePodcasts} color="white" boxSize={5} />
        </a>
      </VStack>
    </Box>
  )
}
