import {
  Stack,
  Button,
  Text,
  VStack,
  Image,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'
import { contactUrl } from '../helpers/routes';
import sunsetCowboy from '../assets/RDM Background Sunset.svg';

export default function WithBackgroundImage() {
  return (
    <Box
    bg={'#181919'}
    pt={15}
    pb={40}
    >
      <VStack
        w={'full'}
        align={'left'}
        justify={'center'}
        mt={20}
        px={useBreakpointValue({ base: 4, md: 8 })}
      >
        <Stack
          maxW={'4xl'}
          align={'center'}
          spacing={6}
          pl={{ lg: 26 }}
          pt={{ lg: 20 }}
          zIndex={1}
        >
          <Text
            textAlign={'center'}
            color={'white'}
            fontWeight={{ lg: 300, base: 400 }}
            pb={{ lg: 14 }}
            fontFamily={'Montserrat'}
            lineHeight={1.2}
            fontSize={{ sm: '72px', base: '50px' }}>
            We are Bitcoin Miners.
          </Text>
          <Image
            display={{ lg: 'none' }}
            borderRadius='full'
            boxSize={{ md: '300px', sm: '250px', base: '200px' }}
            src={sunsetCowboy}
            alt='A cowboy surveying the land with a pumpjack from a horse on a rocky outcrop'
          />
          <Text
            textAlign={'center'}
            color={'white'}
            fontWeight={600}
            fontFamily={'Montserrat'}
            fontSize={{ sm: '20px', base: '14px' }}
            pb={{ lg: 20 }}
          >
            <em>
              Emerging from the iconic red dirt of Oklahoma, Red Dirt Mining is committed to building the tools,
              infrastructure and operational expertise you need to simplify your Bitcoin mining operations. We work with
              energy producers, utility companies, policy-makers, and fellow mining companies through our suite of hardware,
              software, and consulting solutions. 
            </em>
          </Text>
          <Button
            as={RouterLink}
            to={contactUrl}
            size={'lg'}
            variant={'outline'}
            color={'white'}
            rounded={'full'}
            fontFamily={'Montserrat'}
            fontWeight={500}
            _hover={{ bg: '#3A355A' }}>
            Talk With Us
          </Button>
        </Stack>
      </VStack>
      <Image
        display={{ base: 'none', lg: 'block' }}
        position={'absolute'}
        top={{ xl: '10%', lg:'20%'}}
        right={{ xl: '10%', lg:'10%'}}
        zIndex={0}
        borderRadius='full'
        boxSize={{ xl: '770px', lg: '550px', md: '300px', sm: '300px', base: '300px' }}
        src={sunsetCowboy}
        alt='A cowboy surveying the land with a pumpjack from a horse on a rocky outcrop'
      />
    </Box>
  );
}
