import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { homeUrl, podcastUrl, teamUrl, contactUrl, calculatorUrl, rabbitHoleUrl } from '../helpers/routes';
import logoCombo from '../assets/logoCombo.png';

const linkColor = 'white'
const linkHoverColor = '#7D443C'
const popoverContentBgColor = '#181919'

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box overflow={'hidden'}>
      <Flex
        bg={useColorModeValue('#181919', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}>
        
        <Flex flex={{ base: 1 }} justify={'start'}>
          <RouterLink to={homeUrl}>
              <Image src={logoCombo} pt={2} pl={4} height={'80px'} />
          </RouterLink>
        </Flex>

        <Flex display={{ base: 'none', md: 'flex' }} ml={10} mr={10}>
          <DesktopNav />
        </Flex>

        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          justify={'end'}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={5} h={5} /> : <HamburgerIcon w={10} h={10} />
            }
            pr={4}
            variant={'ghost'}
            color='white'
            aria-label={'Toggle Navigation'}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const location = useLocation()
  return (
    <Stack
      direction={'row'}
      spacing={4}
      justify={'flex-end'}
    >      
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                as={RouterLink}
                to={navItem.route ?? '#'}
                p={2}
                fontSize={14}
                fontFamily={'Montserrat'}
                fontWeight={500}
                color={navItem.route === location.pathname || (navItem.children && navItem.children.find((item) => item.route === location.pathname)) ? linkHoverColor : linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label.toUpperCase()}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                // boxShadow={'xl'}
                bg={popoverContentBgColor}
                // p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack spacing={'-2px'}>
                  {navItem.children.map((child) => (
                    child.route ? <DesktopSubNav key={child.label} {...child} /> : <ExternalSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, route, subLabel }) => {
  const location = useLocation()
  return (
    <Link
      as={RouterLink}
      to={route}
      role={'group'}
      display={'block'}
      // p={2}
      border={'1px solid #ffffff'}
      // _hover={{ bg: '#3A355A' }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box
          p={8}
        >
          <Text
            color={route === location.pathname ? linkHoverColor : linkColor}
            transition={'all .3s ease'}
            _groupHover={{ color: linkHoverColor }}
            fontSize={'14px'}
            fontFamily={'Montserrat'}
            fontWeight={500}>
            {label.toUpperCase()}
          </Text>
          <Text fontSize={'sm'} color={linkColor}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={linkHoverColor} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const ExternalSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      isExternal
      role={'group'}
      display={'block'}
      // p={2}
      border={'2px solid #ffffff'}
      // _hover={{ bg: '#3A355A' }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box
          p={8}
        >
          <Text
            color={linkColor}
            transition={'all .3s ease'}
            _groupHover={{ color: linkHoverColor }}
            fontSize={'14px'}
            fontFamily={'Montserrat'}
            fontWeight={500}>
            {label.toUpperCase()}
          </Text>
          <Text fontSize={'sm'} color={linkColor}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={linkHoverColor} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={'#181919'}
      color='white'
      spacing={'-1px'}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, route, active }) => {
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation()
  const linkColor = 'white'
  const linkHoverColor = '#7D443C'

  return (
    <Stack spacing={'-1px'} onClick={children && onToggle}>
      <Box
        p={8}
        border={'1px solid #ffffff'}
        textAlign={'center'}
      >
        <Link
          fontFamily={'Montserrat'}
          fontWeight={500}
          color={route === location.pathname || (children && children.find((item) => item.route === location.pathname)) ? linkHoverColor : linkColor}
          as={RouterLink}
          to={route ?? '#'}
        >
          {label.toUpperCase()}
        </Link>
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          spacing={'-1px'}
          textAlign={'center'}
        >
          {children &&
            children.map((child) => (
              child.route ? (
                <Box
                  p={8}
                  border={'1px solid #ffffff'}
                >
                  <Link
                    fontFamily={'Montserrat'}
                    fontWeight={500}
                    color={child.route === location.pathname ? linkHoverColor : linkColor}
                    as={RouterLink}
                    to={child.route ?? '#'}
                    key={child.label}
                    py={2}
                  >
                    {child.label.toUpperCase()}
                  </Link>
                </Box>
              ) : (
                <Box
                p={8}
                border={'1px solid #ffffff'}
                >
                  <Link
                    fontFamily={'Montserrat'}
                    fontWeight={500}
                    color={linkColor}
                    href={child.href ?? '#'}
                    key={child.label}
                    py={2}
                  >
                    {child.label.toUpperCase()}
                  </Link>
                </Box>
              )
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Team',
    route: teamUrl,
  },
  {
    label: 'Products',
    children: [
      {
        label: 'Calculator',
        // subLabel: 'Free and open source tool to calculate your mining profitability in sats.',
        route: calculatorUrl,
      },
    ],
  },
  {
    label: 'Education',
    children: [
      {
        label: 'Podcast',
        // subLabel: 'Learn about the mining industry',
        href: podcastUrl,
      },
      {
        label: 'Mining Rabbit Hole',
        // subLabel: 'Learn about the mining industry',
        href: rabbitHoleUrl,
      },
    ],
  },
  {
    label: 'Contact',
    route: contactUrl,
  },
];
