import { Container } from '@chakra-ui/react';

const { privacyHtml } = require('./privacy.js');

const PrivacyPolicy = () => (
  <Container
    pt={10}
    pb={10}
  >
    <div dangerouslySetInnerHTML={{ __html: privacyHtml }} />
  </Container>
);

export default PrivacyPolicy;
