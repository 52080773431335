export const homeUrl = '/'
export const teamUrl = '/team'
export const calculatorUrl = '/calculator'
export const contactUrl = '/contact'
export const privacyUrl = '/privacy'
export const calculatorGithubUrl = 'https://github.com/Red-Dirt-Mining/calculator'
export const twitterUrl = 'https://twitter.com/reddirtmining'
export const podcastUrl = 'https://anchor.fm/difficultyadjusted'
export const blogUrl = 'https://blog.reddirtmining.io'
export const rabbitHoleUrl = 'https://www.swanbitcoin.com/canon/under-the-hood-of-bitcoin-mining/'
export const emailAddress = 'mailto:difficultyadjusted@reddirtmining.io'
export const linkedinUrl = 'https://www.linkedin.com/company/reddirt/'
export const meetupUrl = 'https://www.meetup.com/reddirtbitcoiners/'
