import * as yup from 'yup'

const validationSchema = yup.object().shape({
  firstName: yup.string().max(20, 'Must be less than 20 characters').required('First name is required'),
  lastName: yup.string().max(20, 'Must be less than 20 characters').required('Last name is required'),
  phone: yup.number().max(99999999999999999999999999, 'Invalid phone number').typeError('Phone must be a number'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  occupation: yup.string().max(50, 'Must be less than 50 digits').required('Occupation is required'),
  message: yup.string().max(1000, 'Must be less than 1000 characters').required('Message is required'),
})

export default validationSchema
