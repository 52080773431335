import {
    Box,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Center,
    Flex,
    Spacer,
    Stack,
} from '@chakra-ui/react';
import React from 'react';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Jack from '../assets/Jack.png';
import Kaz from '../assets/Kaz.png';
import Storm from '../assets/Storm.png';
import Zane from '../assets/Zane.png';

const Banner = () => {
  return (
    <Heading
      as='h1'
      fontSize='5xl'
      fontFamily={'Montserrat'}
      fontWeight={600}
      align='center'
      pt={10}
      pb={20}
    >
      FOUNDING TEAM
    </Heading>
  )
}

const TeamMember = ({ member }) => {
  return (
    <Box>
      <Center>
        <Image
          boxSize='320px'
          borderRadius='full'
          objectFit='cover'
          src={member.profileImage}
          alt={member.name}
        />
      </Center>
      <Text align='center' fontSize={20} pt={9}>
        {member.name}
      </Text >
      <Text align='center' fontSize={14}>
        {member.position}
      </Text>
      <Text align='center' fontSize={12} pb={6}>
        {member.background}
      </Text>
      <Stack direction='row' align='center' justify='center' spacing={9}>
        <a href={`https://twitter.com/${member.twitterHandle}`} target='_blank' rel='noopener noreferrer'>
          <FaTwitter size={20} />
        </a>
        <a href={`https://www.linkedin.com/in/${member.linkedinHandle}/`} target='_blank' rel='noopener noreferrer'>
          <FaLinkedin size={20} />
        </a>
        <a href={`mailto:${member.email}`} target='_blank' rel='noopener noreferrer'>
          <MdEmail size={20} />
        </a>
      </Stack>
    </Box>
  )
}


export default function TeamMembers() {
    const kaz = {
        name: 'Kaz Bycko',
        alt: 'Kaz Bycko',
        position: 'Cofounder, Chief Executive Officer',
        background: 'Swan Bitcoin, Accenture',
        twitterHandle: 'btckaz',
        linkedinHandle: 'kazbycko',
        email: 'kaz@reddirtmining.io',
        profileImage: Kaz,
    }
    const zane = {
        name: 'Zane Pocock',
        alt: 'Zane Pocock',
        position: 'Cofounder, Chief Technology Officer',
        background: 'Swan Bitcoin, Knox Custody',
        twitterHandle: 'zanepocock',
        linkedinHandle: 'zanepocock',
        email: 'zane@reddirtmining.io',
        profileImage: Zane,

    }
    const storm = {
        name: 'Storm Rund',
        alt: 'Storm Rund',
        position: 'Cofounder, Chief Operating Officer',
        background: 'NYU Law, Difficulty Adjusted Podcast',
        twitterHandle: '_BTCstorm',
        linkedinHandle: 'storm-r-45101177',
        email: 'storm@reddirtmining.io',
        profileImage: Storm,
    }
    const jack = {
        name: 'Jack Pennebaker',
        alt: 'Jack Pennebaker',
        position: 'Cofounder, Head of Growth',
        background: 'APMEX, Rackspace',
        twitterHandle: 'Sats_quatch',
        linkedinHandle: 'john-pennebaker-405',
        email: 'jack@reddirtmining.io',
        profileImage: Jack,
    }

    const teamMembers = [kaz, zane, storm, jack]

    return (
      <Box
        bgGradient="linear(180deg, #181919 18.75%, #7D443C 100%)"
        color={'white'}
        fontFamily={'Montserrat'}
        pt={5}
        pb={10}
      >
        <Banner />
        <Flex>
          <Spacer />
            <SimpleGrid columns={{sm: 1, lg: 2}} spacingX={64} spacingY={12} maxW='8xl'>
              {teamMembers.map(member => (
                <TeamMember member={member} />
              ))}
            </SimpleGrid>
          <Spacer />
        </Flex>
      </ Box>
    );
}
