import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';

import { homeUrl, teamUrl, calculatorUrl, contactUrl, privacyUrl } from './helpers/routes';

import Navbar from './components/navbar';
import FloatingSocialNavbar from './components/navSocial';
import Footer from './components/footer';
import Home from './pages/Home';
import Team from './pages/Team';
import Contact from './pages/Contact';
import Calculator from './pages/Calculator';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Navbar />
        <FloatingSocialNavbar />
        <Routes>
          <Route path={homeUrl} element={<Home />} />
          <Route path={teamUrl} element={<Team />} />
          <Route path={calculatorUrl} element={<Calculator />} />
          <Route path={contactUrl} element={<Contact />} />
          <Route path={privacyUrl} element={<PrivacyPolicy />} />
          <Route path='*' element={<div>Not Found</div>} /> { /* TODO: 404 page */ }
        </Routes>
        <Footer />
      </ChakraProvider>
    </Router>
  );
}

export default App;
